var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-wrap py-2"},[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":""},on:{"click":_vm.getRemesas}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v("Recargar ")],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"fixed-checkbox",attrs:{"fixed-header":"","items":_vm.remesas,"headers":_vm.remesasHeaders,"show-select":"","item-key":"IdRemesaCobro","single-select":false,"calculate-widths":true,"footer-props":{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [10, 25, 50],
        },"checkbox-color":"secondary"},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
        var headers = ref.headers;
return [_c('table-filters',{attrs:{"headers":headers,"items":_vm.remesas},model:{value:(_vm.inlineFilters),callback:function ($$v) {_vm.inlineFilters=$$v},expression:"inlineFilters"}})]}},{key:"item.FechaRemesa",fn:function(ref){
        var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaRemesa))}})]}},{key:"item.FechaCargo",fn:function(ref){
        var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaCargo))}})]}},{key:"item.ImporteRemesa",fn:function(ref){
        var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(parseFloat(item.ImporteRemesa).toFixed(2) + ' €')}})]}},{key:"footer.prepend",fn:function(){return [_c('status-chip',{attrs:{"value":_vm.$store.getters.getEntorno}})]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('div',{staticClass:"d-flex flex-wrap mt-4 align-center"},[_c('strong',{staticClass:"mr-2"},[_vm._v("Total importes "+_vm._s(_vm.selected.length == 0 ? "registros filtrados" : "registros seleccionados")+" :")]),_vm._v(" "+_vm._s(_vm.acumuladoRemesas)+" "),_c('v-btn',{staticClass:"ml-2",attrs:{"text":"","color":"primary","small":"","disabled":_vm.selected.length == 0},on:{"click":function($event){_vm.selected = []}}},[_vm._v("Deseleccionar todos ("+_vm._s(_vm.selected.length)+")")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }