<template>
  <v-container fluid>
    <div class="d-flex flex-wrap py-2">
      <v-btn class="ma-1" color="primary" small @click="getRemesas">
        <v-icon left>mdi-reload</v-icon>Recargar
      </v-btn>
    </div>

    <v-card>
      <v-data-table
        fixed-header
        :items="remesas"
        :headers="remesasHeaders"
        show-select
        class="fixed-checkbox"
        item-key="IdRemesaCobro"
        v-model="selected"
        :single-select="false"
        :calculate-widths="true"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [10, 25, 50],
        }"
        checkbox-color="secondary"
      >
        <template v-slot:body.prepend="{ headers }">
          <table-filters
            :headers="headers"
            :items="remesas"
            v-model="inlineFilters"
          ></table-filters>
        </template>

        <template v-slot:item.FechaRemesa="{ item }">
          <span v-text="parseDate(item.FechaRemesa)"></span>
        </template>
        <template v-slot:item.FechaCargo="{ item }">
          <span v-text="parseDate(item.FechaCargo)"></span>
        </template>

        <template v-slot:item.ImporteRemesa="{ item }">
          <span v-text="parseFloat(item.ImporteRemesa).toFixed(2) + ' €'" />
        </template>

        <template v-slot:footer.prepend>
					<status-chip :value="$store.getters.getEntorno" />
				</template>

      </v-data-table>
    </v-card>

    <div class="d-flex flex-wrap mt-4 align-center">
      <strong class="mr-2"
        >Total importes
        {{
          selected.length == 0
            ? "registros filtrados"
            : "registros seleccionados"
        }}
        :</strong
      >
      {{ acumuladoRemesas }}
      <v-btn
        text
        color="primary"
        class="ml-2"
        small
        :disabled="selected.length == 0"
        @click="selected = []"
        >Deseleccionar todos ({{ selected.length }})</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import { getSituacionIcon, perColumnFilter, parseDate } from "@/utils/index.js";

export default {
  components: {
    StatusChip: () => import("@/components/StatusChip.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
  },
  data() {
    return {
      remesas: [],
      selected: [],
      inlineFilters: {},
      remesasHeaders: [
        { text: "Fecha Remesa", dataType: "date", value: "FechaRemesa" },
        { text: "Fecha Cargo", dataType: "date", value: "FechaCargo" },
        { text: "Banco", value: "TextoBanco", dataType: "select" },
        { text: "Importe", value: "ImporteRemesa" },
        { text: "Nº Facturas", value: "NFacturas" },
      ].map((header) => ({
        class: "text-no-wrap sticky-header",
        cellClass: "pa-2 text-no-wrap",
        filter: (value) =>
          perColumnFilter(
            value,
            this.inlineFilters[header.value],
            header.dataType
          ),
        dataType: "text",
        ...header,
      })),
    };
  },
  computed: {
    acumuladoRemesas() {
      if (this.selected.length) {
        return (
          parseFloat(
            this.selected.reduce((a, c) => a + Number(c.ImporteRemesa), 0)
          ).toFixed(2) + " €"
        );
      } else {
        // return parseFloat(this.facturas.reduce((a, c) => a + Number(c.ImporteTotal), 0)).toFixed(2) + ' €'
        const getDatatype = (key) =>
          (this.remesasHeaders.find((h) => h.value == key) || {}).dataType;

        return (
          parseFloat(
            this.remesas
              .filter((con) =>
                Object.entries(con).every(([key, value]) =>
                  perColumnFilter(
                    value,
                    this.inlineFilters[key],
                    getDatatype(key)
                  )
                )
              )
              .map((i) => Number(i.ImporteRemesa))
              .reduce((a, c) => a + c, 0)
          ).toFixed(2) + " €"
        );
      }
    },
  },
  methods: {
    getSituacionIcon,
    parseDate,
    async getRemesas() {
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OUR_API_URL}/remesas.php`,
        params: {
          token: this.$store.getters.getJwtEmpresa
        }
      });

      this.remesas = data;
    },
  },
  mounted() {
    this.getRemesas();
  },
};
</script>
